<template>
  <v-row justify="center">
    <v-col cols="11">
      <v-card flat v-if="votes">
        <v-card-title>Liste des votes</v-card-title>
        <v-card-text>
          <v-card
            v-for="vote in votes"
            :key="vote._id"
            class="pa-2 mb-2"
            color="amber lighten-5"
          >
            <v-card-title style="word-break: keep-all">{{ vote.title }}</v-card-title>
            <v-card-subtitle>{{ vote.description }}</v-card-subtitle>
            <v-card-actions>
              <template v-if="opened(vote)">
                <v-chip v-if="!vote.voted" class="mr-2" color="green"
                  >Ouvert</v-chip
                >
                <v-chip v-else>Voté !</v-chip>
                <v-btn
                  v-if="!vote.voted && vote.inVoters"
                  outlined
                  color="brown darken-3"
                  :to="'/vote?id=' + vote._id"
                >
                  Répondre
                </v-btn>
                <v-chip v-else-if="!vote.inVoters">Vous ne pouvez pas voter
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon right color="info" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>Ton compte ne figure pas dans la liste des votants<br/> communiquée à DaTA. Si tu penses que c'est une<br/>
                      erreur merci de contacter le BDE{{ contactBDE() }}.</span>
                  </v-tooltip>
                </v-chip>
              </template>
              <v-chip v-else-if="closed(vote)" color="amber"
                >Vote terminé</v-chip
              >
              <v-chip v-else color="cornflowerblue"
                >Vote ouvrira le {{ formatDate(vote.begin) }}</v-chip
              >
              <v-btn
                outlined
                v-if="vote.admin"
                class="ml-3"
                color="amber darken-4"
                :to="'/manage?id=' + vote._id"
              >
                Admin
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="loading" dense persistent width="67">
      <v-card class="pa-4 text-center">
        <v-progress-circular indeterminate class="pa-3"></v-progress-circular>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: "HomePage",
  data() {
    return {
      loading: true,
      votes: undefined,
    };
  },
  mounted() {
    this.axiosPost(process.env.VUE_APP_API_URL + "/api/getVotes")
      .then((resp) => {
        this.votes = resp.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    opened(vote) {
      return (
        new Date(vote.end).getTime() >= Date.now() &&
        Date.now() >= new Date(vote.begin).getTime()
      );
    },
    closed(vote) {
      return Date.now() >= new Date(vote.end).getTime();
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("fr", {
        dateStyle: "short",
        timeStyle: "medium",
      }).format(new Date(date));
    },
    contactBDE() {
      return process.env.VUE_APP_CONTACT_BDE ? process.env.VUE_APP_CONTACT_BDE : ""
    },
  },
};
</script>